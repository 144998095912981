#orderlogo * {
    visibility: hidden;
    display: none;
}

@media print {
    body * {
        visibility: hidden;
    }

    #print-order,
    #print-order * {
        visibility: visible;
    }

    #order-action * {
        visibility: hidden;
    }

    #tabbox * {
        visibility: hidden;
    }

    #main-content {
        width: 100% !important;
        margin-top: 0px;
    }

    #orderlogo * {
        visibility: visible;
        display: block;

    }

    #orderlogo {
        text-align: center;
        margin: 0 ;
       
    }

    #orderlogo img {
        width: 50%;
        text-align: center;
        /* margin-left:25%; */
        margin-bottom: 35px;
        margin-left: auto;
        margin-right: auto;
    }

    #print-order {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
    }

}